import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

// 년도 출력 함수
export const getYear = t => {
  //var myDate = new Date( timestamp *1000 );
  //return myDate.getFullYear();
  if( t ) {
    return t.substr(0,4);
  } else {
    return;
  }
}

// 날짜 출력 함수
export const getDay = t => {
  /*
  var myDate = new Date( timestamp *1000);
  var mm = (myDate.getMonth()+1<10)? '0'+(myDate.getMonth()+1):myDate.getMonth()+1;
  var dd = (myDate.getDate()<10)? '0'+myDate.getDate():myDate.getDate();
  return mm+'-'+dd;
  */
  if( t ) {
    return t.substr(5,5);
  } else {
    return;
  }
}

// 시간 출력 함수
export const getTime = t => {
  /*
  var myDate = new Date( timestamp *1000);
  var hh = (myDate.getHours()<10)? '0'+myDate.getHours():myDate.getHours();
  var min = (myDate.getMinutes()<10)? '0'+myDate.getMinutes():myDate.getMinutes();
  return hh+":"+min;
  */
  if( t ) {
    return t.substr(11,5);
  } else {
    return;
  }
}

// 리그이미지 출력
export const makeLeagueImg = ( data ) => {
  if( data.league == 'UFC' || data.league == 'UFC Fight Night' ) {
    return 'https://sports.honey-game.com/img/ufc.png';
  } else if( data.league == 'RIZIN' ){
    return 'https://sports.honey-game.com/img/rizin.png';  
  } else if( data.league == 'KSW' ){
    return 'https://sports.honey-game.com/img/ksw.png';  
  } else if( data.league_cc == undefined || data.league_cc == 'null'){
    return 'https://sports.honey-game.com/img/'+data.sports_id+'.png';  
  } else {
    return "https://assets.betsapi.com/v2/images/flags/"+data.league_cc+".svg";
  }
}

// 팀 이미지 출력
export const makeTeamImg = ( cc, pos ) => {
  if( cc == undefined || cc == 'null'){
    if( pos == 1 ) return 'https://sports.honey-game.com/img/emblem_red.png';  
    if( pos == 2 ) return 'https://sports.honey-game.com/img/emblem_blue.png';  
  } else {
    return "https://assets.b365api.com/images/team/s/"+cc+".png";  
  }
}

export const makeTeamImgLarge = ( cc, pos ) => {
  if( cc == undefined || cc == 'null'){
    if( pos == 1 ) return 'https://sports.honey-game.com/img/emblem_red.png';  
    if( pos == 2 ) return 'https://sports.honey-game.com/img/emblem_blue.png';  
  } else {
    return "https://assets.b365api.com/images/team/b/"+cc+".png";
  }
}
