<template>
  <div
    class="customizer"
    :class="{'open': isCustomizerOpen}"
  >
    <GameCart/>
    
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        :badge="bet_cnt"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
      
    </b-link>

  </div>
  
</template>

<script>
import {BLink, BFormRadioGroup, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import useAppCustomizer from './useAppCustomizer'
import GameCart from '@/views/games/GameCart'
import EventBus from '@/event-bus';

export default {
  data () {
    return {
      bet_cnt: 0,
    }
  },
  components: {
    GameCart,
    // BSV
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,


  },
  created () {
    if( localStorage.getItem("betData") != null){
      this.bet_cnt = JSON.parse(localStorage.getItem("betData")).length;
    }
  },
  mounted(){
    EventBus.$on('remove_bet', (obj)=>{
      if( localStorage.getItem("betData") != null){
        this.bet_cnt = JSON.parse(localStorage.getItem("betData")).length;
      }
    });
    EventBus.$on('add_bet', (obj)=>{
      if( localStorage.getItem("betData") != null){
        this.bet_cnt = JSON.parse(localStorage.getItem("betData")).length;
      }
    });
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

   
    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}

.customizer-toggle .badge {background-color:rgba(0,0,0,0.5);}
.dark-layout .text-body {color:white !important;}
</style>
