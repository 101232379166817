<template>
  <b-row class="betting-list">
    <b-col>
      <b-row>
        <b-col class="betting-list-title">
          {{ view_mode }}
          <b-button v-if="use_cancel == '1' && view_mode=='베팅상세내역'" @click="cancel()" class="btn btn-sm btn-success btn-cancel">취소</b-button>
          <!--
          <b-button v-if="cancelable == true && view_mode=='베팅상세내역' && selected.result == '대기중' && use_cancel == '1'" @click="cancel()" class="btn btn-sm btn-success btn-cancel">취소</b-button>
          <b-button v-else-if="view_mode=='베팅상세내역' && use_cancel == '1'" @click="cancel()" class="btn btn-sm btn-secondary btn-cancel">취소</b-button>
          -->
        </b-col>
      </b-row>

      <b-row style="justify-content: center; letter-spacing:-0.5px">
        <b-table v-if="view_mode=='베팅내역'"
          responsive
          :items="betList"
          :fields="fields"
          class="mb-0"
          hover
          @row-clicked="onRowSelected"
        >
          <template #cell(result)="data">
            <template v-if="data.value === '적중'">
              <b-badge pill variant="light-success" >적중</b-badge>
            </template>
            <template v-else-if="data.value === '미적중'">
              <b-badge pill variant="light-danger" >미적중</b-badge>
            </template>
            <template v-else-if="data.value === '적특'">
              <b-badge pill variant="light-warning" >적특</b-badge>
            </template>
            <template v-else-if="data.value === '베팅취소'">
              <b-badge pill variant="light-primary" >베팅취소</b-badge>
            </template>
            <template v-else>
              <b-badge pill variant="light-secondary" >대기중</b-badge>
            </template>
          </template>
        </b-table>

        <b-col cols="12" v-else-if="view_mode=='베팅상세내역'" class="my-h p-h">
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="betting-list-scroll-area"
            :always-show-scrollbar="true"
          >
          
          <b-row :key="i" v-for="(item, i) in betEx" class="betting-content">
            <b-col cols="9" class="pl-2">
              {{ GetSportSImg(item.sports_name) }} {{ item.sports_name }}<b-img class="country-img-16 ml-1" :src="makeLeagueImg(item)"></b-img> {{ item.league_name }} 
            </b-col>
            <b-col cols="3" class="ta-right pr-2">
               <span v-html="GetStrResult(item.result)"></span>
            </b-col>
            <b-col cols="12"><hr></b-col>
            <b-col cols="12" class="pl-2" v-if="item.sports_name == '축구' || item.sports_name == '배구'">
              <span style="color:bisque" class="mr-1">{{ item.game_time }}</span> {{ item.home_name }} <b-badge pill variant="light-secondary mx-h" style="width:initial;" >VS</b-badge> {{ item.away_name }}
            </b-col>
            <b-col cols="12" class="pl-2" v-else-if="item.sports_name == '야구' || item.sports_name == '아이스하키' || item.sports_name == '농구'">
              <span style="color:bisque" class="mr-1">{{ item.game_time }}</span> {{ item.away_name }} <b-badge pill variant="light-secondary mx-h" style="width:initial;" >VS</b-badge> {{ item.home_name }}
            </b-col>
            <b-col cols="12" class="pl-2" v-else>
              <span style="color:bisque" class="mr-1">{{ item.game_time }}</span> {{ item.home_name }} <b-badge pill variant="light-secondary mx-h" style="width:initial;" >VS</b-badge> {{ item.away_name }}
            </b-col>
            <b-col cols="12"><hr></b-col>
            <b-col cols="9" class="pl-2">
              <span class="mr-1">[ {{ item.odds_name }} ]</span>
              <span v-if="item.choice == 1" style="color:royalblue;font-weight:bold;"> {{ item.home_name }} 
                <b-avatar size="sm" variant="light-success" style="margin-bottom:3px;" text="승" />
              </span>
              <span v-else-if="item.choice == 2" style="color:royalblue;font-weight:bold;"> {{ item.away_name }}
                <b-avatar size="sm" variant="light-success" style="margin-bottom:3px;" text="승" />
              </span>
              <span v-else style="color:royalblue;font-weight:bold;"> {{ item.choice }}</span>

              <span v-if="item.odds_name != 'Full Time Result' && item.odds_name != '1st Set WinLose'" class="ml-1" style="color:royalblue;font-weight:bold;">( {{item.options}} )</span>
            </b-col>
            <b-col cols="3" class="ta-right odds pr-2">
              <feather-icon icon="XIcon" size="12"></feather-icon> {{ item.odds }}
            </b-col>
          </b-row>

          <b-row v-if="selected.bonus_rate != 1" class="bet-data m-0 p-h gradient-border2">
            <b-col cols="12" class="m-0 p-0 ta-right pr-2">
              <span class="odds-name mr-1"> 🍀 보너스 배당</span>
              <span class="odds-rate">{{ selected.bonus_rate }}</span>
            </b-col>
          </b-row>
          </vue-perfect-scrollbar>

          <b-row class="betting-bottom">
            <b-col cols="12" class="ta-center">
              조합수 <span class="text-info mr-1">{{ selected.betting_count }}</span>
              베팅금 <span class="text-info mr-1">{{ selected.betting_money }}</span>
              배당 <span class="text-info mr-1">{{ selected.total_odds }}</span>
              예상적중금 <span class="text-info">{{ selected.result_money }}</span>
            </b-col>
          </b-row>
          
        </b-col>

        <!-- 리스트의 페이징 처리 -->
        <div class="demo-spacing-1" v-if="view_mode=='베팅내역'">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @page-click="pageClick"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon"  size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>

       
        <b-button v-else-if="view_mode=='베팅상세내역'"  variant="primary" class="shadow w-100" @click="showBetList">
          <feather-icon icon="ChevronsLeftIcon"></feather-icon>  돌아가기
        </b-button>
      </b-row>
        
    </b-col>

  </b-row>

</template>

<script>
import { BImg, BButton, BRow, BCol, BModal, BTable, BBadge, BPagination,BAvatar } from 'bootstrap-vue'
import { makeLeagueImg, makeTeamImg } from '@core/utils/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useJwt from '@/auth/jwt/useJwt'
import EventBus from '../../event-bus';

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: 'cart_idx', thClass:'d-none', tdClass:'d-none'},
        { key: 'bonus_rate', thClass:'d-none', tdClass:'d-none'},
        { key: 'regdate', label: '베팅일자', thClass:'ta-center px-0', tdClass:'ta-center px-0'},
        { key: 'betting_count', label: '조합수', thClass:'ta-center px-0', tdClass:'ta-center px-0' },
        { key: 'total_odds', label: '배당합계', thClass:'ta-center px-0', tdClass:'ta-center px-0' },
        { key: 'betting_money', label: '베팅금액', thClass:'ta-center px-0', tdClass:'ta-center px-0' },
        { key: 'result_money', label: '예상적중금', thClass:'ta-center px-0', tdClass:'ta-center px-0' },
        { key: 'result', label: '결과', thClass:'ta-center px-0', tdClass:'ta-center px-0' },
      ],
      betList: [], 
      betEx: [], 
      view_mode : '베팅내역',
      selected : [],
      cancelable : false,
      use_cancel : 0,
    }
  },
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    BModal,
    BTable,
    BBadge,
    BPagination,
    BAvatar,
    VuePerfectScrollbar,
    EventBus
  },
  created(){
  },
  mounted() {
    // 쿠키에서 유저데이터 로드
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
      this.use_cancel = this.users.use_cancel; 
    }
    this.showBetList();
  },
  methods: {
    checkAuth(){
      const at = localStorage.getItem("accessToken");
      const rt = localStorage.getItem("refreshToken");

      this.$api('http://server.honey-game.com/auth/account', 'post', {
        'at' : at,
        'rt' : rt
      }).then((res)=>{
        if(res.result == 1 ) {
          EventBus.$emit('update_user', res.userData);
          this.users = res.userData;
          useJwt.setToken(res.access_token)
          useJwt.setRefreshToken(res.refresh_token)
          localStorage.setItem('userData', JSON.stringify(this.users))
        } else {
          this.$router.replace('/pages/miscellaneous/not-authorized');
        }
      });
    },
    async cancel(){
      if( this.view_mode == '베팅내역' ) return;
      //if( this.cancelable == false || this.selected.result != '대기중' ) return;

      this.$swal({
        title: '',
        text: "취소하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-h',
        },
        buttonsStyling: false,
      }).then (async result => {
        if (result.isConfirmed) {
          await this.$api('http://server.honey-game.com/games/cancel', 'post', {
            at: localStorage.getItem("accessToken"),
            rt: localStorage.getItem("refreshToken"),
            cart_idx: this.selected.cart_idx
          }).then(datas =>{
            if( datas.result == 0 ){
              this.$swal({
                icon: 'error',
                title: '베팅 취소 실패',
                text: datas.msg,
                customClass: {
                  confirmButton: 'btn btn-error',
                },
              });
            } else {
              this.$swal({
                icon: 'success',
                title: '베팅 취소 완료',
                text: datas.msg,
                showConfirmButton: false,
                timer: 1000,
              });
              this.selected.result = '베팅취소';
              this.checkAuth();
            }
          });
        }
      });
    },
    async showBetList(){
      await this.$api('http://server.honey-game.com/games/bet_list', 'post', {
        at: localStorage.getItem("accessToken"),
        rt: localStorage.getItem("refreshToken"),
        page : this.currentPage
      }).then(datas =>{
        if( datas.result == 0 ){
          this.$swal({
            icon: 'error',
            title: '베팅내역 조회 실패',
            text: datas.msg,
            customClass: {
              confirmButton: 'btn btn-error',
            },
          });

          return;
        }
        this.betList = datas.datas;
        this.rows = datas.total_row;
        this.view_mode = '베팅내역';
      })
    },
    async onRowSelected(items) {
      if( this.view_mode == '베팅내역'){
        // 상세정보 GET
        await this.$api('http://server.honey-game.com/games/bet_data', 'post', {
          at: localStorage.getItem("accessToken"),
          rt: localStorage.getItem("refreshToken"),
          cart_idx: items.cart_idx
        }).then(datas =>{
          this.betEx = datas.betData;
          this.selected = items;
          this.view_mode = '베팅상세내역';
          this.cancelable = datas.cancelable;
        })
      }else
      {
        this.view_mode = '베팅내역';
      }
    },
    GetSportSImg(name){
      if(name == "축구") return "⚽";
      if(name == "야구") return "⚾";
      if(name == "아이스하키") return "🏒";
      if(name == "농구") return "🏀";
      if(name == "배구") return "🏐";
      if(name == "E-SPORTS") return "💻";
      if(name == "MMA") return "👊";
    },
    GetStrResult(result){
      if(result==0) return "<span class='text-secondary'>대기중</span>";
      if(result==1) return "<span class='text-success'>적중</span>";
      if(result==2) return "<span class='text-danger'>미적중</span>";
      if(result==3) return "<span class='text-warning'>적특</span>";
    },
    makeLeagueImg, 
    makeTeamImg,
    pageClick: function (button, page){
      this.currentPage = page;
      this.showBetList();
		},
  },
  setup(){
    const perfectScrollbarSettings = {
      suppressScrollX : true,
      scrollingThreshold: 100000,
      wheelSpeed:1,
      swipeEasing:true,
    }
    return{
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
.betting-list-scroll-area {max-height:400px;}
.betting-list .betting-list-title { display:flex;justify-content: center; width:100%; background-color:#2b3654;padding:10px; color:#ffe588; border-bottom:1px solid #ffe588;}
.betting-list .b-pagination {justify-content:center;margin-top:10px;}

.betting-list .badge-pill {width: 50px; margin-top:0px !important; font-size:0.75rem !important;}
.betting-list .betting-bottom { background-color:#364672; padding:1rem 0rem; border:1px solid #090908;margin:3px 0px;}
.betting-list .betting-content { background-color:#19223a; padding:0.5rem 0rem; border:1px solid #090908;margin:5px 0px; }
.betting-list .odds { color:#ffe588; font-weight: bold;}
.betting-list hr {border-color:#2f3440 !important; margin:8px 0px;}
.betting-list .badge { padding:0.3rem 0.5rem !important;}
.betting-list .country-img-16 {width:16px; height:16px;}
.betting-list .b-avatar { width:1.2rem;height:1.2rem;vertical-align: unset;}
.gradient-border2 {background-color: blueviolet !important;}
.btn-cancel {position:absolute;right:10px;padding: .15rem 0.8rem !important;}
</style>