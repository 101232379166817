<template>
  <div class="game-cart-area">
    <!--<Login/>-->
    <!-- 베팅내역 창 -->
    <b-modal ref="modal-bet-list" id="modal-bet-list" centered size="lg" title="" hide-footer>
      <BettingList/>
    </b-modal>

	<b-alert variant="primary" show class="mb-0" style="border-radius:0px !important;text-align:center;padding:5px 0px;">
      <span class="brand-logo">
        <b-img :src="megalineLogo" alt="logo" v-if="users.logo == 'megaline'" />
        <b-img :src="honeyLogo"   alt="honey" v-else-if="users.logo == 'honey'" />
        <!---->
        <b-img :src="madeLogo"    alt="made" v-else-if="users.logo == 'made'" />
        <b-img :src="eagleLogo"   alt="eagle" v-else-if="users.logo == 'eagle'" />
        <b-img :src="jumpLogo"    alt="jump" v-else-if="users.logo == 'jump'" />
        <b-img :src="bingoLogo"   alt="sc" v-else-if="users.logo == 'bingo'" />
        <b-img :src="aromaLogo"   alt="sc" v-else-if="users.logo == 'aroma'" />
        <b-img :src="assaLogo"    alt="assa" v-else-if="users.logo == 'assa'" />
        <b-img :src="bbrLogo"    alt="bbr" v-else-if="users.logo == 'bbr'" />
        <!---->
        <b-img :src="demoLogo"    alt="sc" v-else/>
      </span>
      <!--
      <h2 class="brand-text mb-0" v-if="!users.logo">
        미등록 사이트
      </h2>
      -->
    </b-alert>

    <div class="alert-body">
      <span style="float:left;margin:10px 0px;margin-left:5px;">🏅 {{ users.mem_id }}</span>
      <span style="float:right;margin:10px 0px;margin-right:5px;">🕒 {{ now }}</span>
    </div>

    <div class="cart-title">
      <dark-Toggler />
      <span style="float:right;cursor:pointer;" id="show-btn" @click="showBetList">📋 베팅내역</span>
    </div>

    <div class="cart-content" v-if="betData.length > 0" v-bind:style="{ height: (scroll_height+5) + 'px' }">
      <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="game-scroll-area"
          :always-show-scrollbar="true"
          v-bind:style="{ height: scroll_height + 'px' }"
        >

      <b-row v-if="bonus==true" class="bet-data m-0 p-h gradient-border">
        <b-col cols="12" class="m-0 p-0">
          <span class="odds-name"> 🍀 보너스 배당</span>
          <span class="odds-rate">{{ bonus_rate }}</span>
        </b-col>
      </b-row>

      <b-row v-for="(item, i) in betData" :key="i" class="bet-data m-0 p-h">
        <b-col cols="11" class="m-0 p-0">
          <b-img class="country-img" :src="makeLeagueImg(item)"></b-img>
          <span class="ml-h">{{ (item.league_code && item.league_code != undefined)?item.league_code:item.league }}</span>
        </b-col>
        <b-col cols="1" class="ta-center m-0 p-0">
          <feather-icon icon="Trash2Icon" v-on:click="removeBet(item, 0, $event)"/>
        </b-col>
        <b-col cols="12" class="m-0 p-0">
          <span>{{ item.odds_name }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-if="item.sports_id == 16 || item.sports_id == 17 || item.sports_id == 18">
          <span>{{ item.away }}</span>
          <span style="color:coral">&nbsp;VS&nbsp;</span>
          <span>{{ item.home }}</span>
        </b-col>
        <b-col cols="12" class="m-0 p-0" v-else>
          <span>{{ item.home }}</span>
          <span style="color:coral">&nbsp;VS&nbsp;</span>
          <span>{{ item.away }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-if="item.sports_id == '162' && item.odds_type == 'WinLose'">
          <span class="odds-name" v-if="item.selected.name == '1'">{{ item.home }} 승</span>
          <span class="odds-name" v-if="item.selected.name == '2'">{{ item.away }} 승</span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-else-if="item.odds_type == 'WinLose' || item.odds_type == '1st Set WinLose'">
          <span class="odds-name" v-if="item.selected.header == '1'">{{ item.home }} 승</span>
          <span class="odds-name" v-if="item.selected.header == '2'">{{ item.away }} 승</span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-else-if="(item.odds_type == 'Winners' || item.odds_type == 'Half Winners')">
          <span class="odds-name" v-if="item.selected.name == '1' || item.selected.header == '1'">{{ item.home }} 승</span>
          <span class="odds-name" v-if="item.selected.name == 'Draw' || item.selected.header == 'Tie'">무승부</span>
          <span class="odds-name" v-if="item.selected.name == '2' || item.selected.header == '2'">{{ item.away }} 승</span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-else-if="item.odds_type == 'Over/Under' || item.odds_type == '5 Innings Over/Under' || item.odds_type == 'Over/Under - Point'">
          <span class="odds-name" v-if="item.sports_id == 1 || item.sports_id == 16 || item.sports_id == 17 || item.sports_id == 18">[ {{ item.selected.header }} ] {{ item.selected.name }} </span>
          <span class="odds-name" v-else>[ {{ item.selected.name }} ] {{ item.selected.handicap }} </span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-else-if="item.odds_type == '1st Half Over/Under' || item.odds_type == '1st Quarter Over/Under' || item.odds_type == '1st Set Over/Under' || item.odds_type == '1st Over/Under'">
          <span class="odds-name" >[ {{ item.selected.header }} ] {{ item.selected.handicap }} </span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>


        <b-col cols="12" class="m-0 p-0" v-else-if="item.odds_type == '1st Half Asian Handicap' || item.odds_type == 'Handicap' || item.odds_type == '1st Quarter Handicap' || item.odds_type == '5 Innings Handicap' || item.odds_type == '1st Handicap'">
          <span class="odds-name" v-if="item.selected.header == '1'">{{ item.home }} ( {{ item.selected.handicap }} )</span>
          <span class="odds-name" v-if="item.selected.header == '2'">{{ item.away }} ( {{ item.selected.handicap }} )</span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>

        <b-col cols="12" class="m-0 p-0" v-else-if="item.odds_type == 'Handicap - Point'">
          <span class="odds-name" v-if="item.selected.header == '1'">{{ item.home }} ( {{ item.selected.name }} )</span>
          <span class="odds-name" v-if="item.selected.header == '2'">{{ item.away }} ( {{ item.selected.name }} )</span>
          <span class="odds-rate">{{ item.selected.odds }}</span>
        </b-col>

      </b-row>
      </vue-perfect-scrollbar>
    </div>

    <div class="cart-content-empty" v-else v-bind:style="{ height: (scroll_height+5) + 'px' }">
      <feather-icon icon="ShoppingCartIcon" size="30"></feather-icon><br><br>
      {{ title }}가 비어 있습니다
    </div>

    <b-row class="py-1">
      <b-col>
        <b-button variant="flat-warning" size="sm" @click="removeAll()">
          <feather-icon icon="Trash2Icon"/><span class="ml-h">전체삭제</span>
        </b-button>
      </b-col>
      <b-col>
        <b-badge
          pill
          :variant="`light-warning`"
          class="text-capitalize mt-0"
        >
          ￦ {{ comma(users.mem_money) }}
        </b-badge>
      </b-col>
    </b-row>

    <hr class="mb-0 mt-0">

    <b-row>
      <b-col style="padding:10px 30px;">조합수</b-col>
      <b-col style="padding:10px 20px;">x <span>{{ getTotalCnt }}</span></b-col>
    </b-row>

    <b-row>
      <b-col style="padding:0px 30px;">전체배당</b-col>
      <b-col style="padding:0px 20px;">x <span>{{ getTotalOdds }}</span></b-col>
    </b-row>

    <b-row>
      <b-col style="padding:10px 30px;">예상 당첨금액</b-col>
      <b-col style="padding:10px 20px;">{{ getResultMoney }}</b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-input-group prepend="￦">
          <cleave
            id="bet_money"
            class="form-control"
            :raw="false"
            :options="options.number"
            @change="calc"
            v-model="bet_money"
          />
          <b-input-group-append is-text>
            <feather-icon icon="RefreshCcwIcon" class="cursor-pointer" @click="resetMoney()"/>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row class="p-0 m-0 pt-1">
      <b-col cols="2" class="p-0 m-0 ta-center"><span class="btn_money" @click="addMoney(10000);">1만</span></b-col>
      <b-col cols="2" class="p-0 m-0 ta-center"><span class="btn_money" @click="addMoney(30000);">3만</span></b-col>
      <b-col cols="2" class="p-0 m-0 ta-center"><span class="btn_money" @click="addMoney(50000);">5만</span></b-col>
      <b-col cols="2" class="p-0 m-0 ta-center"><span class="btn_money" @click="addMoney(100000);">10만</span></b-col>
      <b-col cols="2" class="p-0 m-0 ta-center"><span class="btn_money" @click="addMoney(500000);">50만</span></b-col>
      <b-col cols="2" class="p-0 m-0 ta-center"><span class="btn_money" @click="addMoney(1000000);">100만</span></b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mt-1">
        <b-button variant="primary" class="shadow w-100" @click="confirmBetting()">
          <feather-icon icon="Link2Icon"/><span class="ml-h">베팅하기</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { getDay, getTime, makeLeagueImg, makeTeamImg } from '@core/utils/utils'
import { BRow, BCol, BImg, BContainer, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BButton, BBadge, BAvatar, BAlert } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import EventBus from '../../event-bus';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useJwt from '@/auth/jwt/useJwt'

import { $themeConfig } from '@themeConfig'

// Components
import Login from './Login.vue'
import BettingList from './BettingList.vue'
import DarkToggler from './DarkToggler.vue'

export default {
  data () {
    return {
      title: '베팅카트',
      datas: [],
      betData : [],
      total_odds : 1.00,
      bet_money : 0,
      users : [],
      bFlag: false,
      options :{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      betList: [],
      //scroll_height : document.documentElement.clientHeight - 500,
      scroll_height : 205,
      bonus : false,
      bonus_rate : 0,
      folder_for_bonus : 0,
      now:"",
      rullData:[]
    }
  },
  components: {
    BRow,BCol,BImg,BContainer,BInputGroup,Cleave,BInputGroupPrepend,BInputGroupAppend,BButton,
    Login, VuePerfectScrollbar, BettingList, BBadge, BAvatar, BAlert,
    DarkToggler
  },
  mounted(){
    window.addEventListener('resize', this.onResize);
    this.updateNow();
    setInterval(this.updateNow.bind(this) , 1000);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  computed:{
    getTotalOdds(){
      return (Math.floor(this.total_odds*100) / 100).toFixed(2);
    },
    getResultMoney(){
      return this.comma((this.uncomma(this.bet_money) * this.total_odds).toFixed(0));
    },
    getTotalCnt(){
      return this.betData.length;
    }
  },
  created () {
    // 쿠키에서 유저데이터 로드
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
      this.bet_money = 0;
    }
    // 쿠키에서 베팅데이터 로드
    if( localStorage.getItem("betData") != null){
      this.betData = JSON.parse(localStorage.getItem("betData"));
      this.calc();
    }

    EventBus.$on('update_user', (obj)=>{
      this.users = obj;
    });

    EventBus.$on('remove_all', ()=>{
      this.betData = [];
    });

    EventBus.$on('update_cart', (obj)=>{
      var bExist = false;
      
      for(var value of this.betData)
      {
        if( obj.selected.id == value.selected.id )
        {
          bExist = true;
          // 삭제
          this.betData = this.betData.filter((element) => element !== value);
          localStorage.setItem("betData", JSON.stringify(this.betData));
          EventBus.$emit('remove_bet', obj.selected.id);
        } 
        else if( obj.game_id == value.game_id )
        {
          if( obj.sports_id == 1 && value.sports_id == 1 ) { // 축구 조합
            if( this.users.mix_soccer_smp_ou == 1 ) {
              if( (obj.odds_name == "Full Time Result" && value.odds_name == "Goals Over/Under") || (obj.odds_name == "Goals Over/Under" && value.odds_name == "Full Time Result") ){
                continue;
              }
            }
            if( this.users.mix_soccer_hd_ou == 1 ) {
              if( (obj.odds_name == "Asian Handicap" && value.odds_name == "Goals Over/Under") || (obj.odds_name == "Goals Over/Under" && value.odds_name == "Asian Handicap") ) {
                continue;
              }
            }
          }

          if( obj.sports_id == 16 && value.sports_id == 16 ) { // 야구 조합
            if( this.users.mix_baseball_smp_ou == 1 ) {
              if( (obj.odds_name == "Win - Lose" && value.odds_name == "Total Over/Under") || (obj.odds_name == "Total Over/Under" && value.odds_name == "Win - Lose") ){
                continue;
              }
            }
            if( this.users.mix_baseball_hd_ou == 1 ) {
              if( (obj.odds_name == "Asian Handicap" && value.odds_name == "Total Over/Under") || (obj.odds_name == "Total Over/Under" && value.odds_name == "Asian Handicap") ) {
                continue;
              }
            }
            if( this.users.mix_baseball_sp == 1 ) {
              if( (obj.odds_name == "Alternative 5 Innings Line" && value.odds_name == "Alternative 5 Innings Total") || (obj.odds_name == "Alternative 5 Innings Total" && value.odds_name == "Alternative 5 Innings Line") ) {
                continue;
              }
            }
          }

          if( obj.sports_id == 18 && value.sports_id == 18 ) { // 농구 조합
            if( this.users.mix_basket_smp_ou == 1 ) {
              if( (obj.odds_name == "Win - Lose" && value.odds_name == "Goals Over/Under") || (obj.odds_name == "Goals Over/Under" && value.odds_name == "Win - Lose") ){
                continue;
              }
            }
            if( this.users.mix_basket_hd_ou == 1 ) {
              if( (obj.odds_name == "Asian Handicap" && value.odds_name == "Goals Over/Under") || (obj.odds_name == "Goals Over/Under" && value.odds_name == "Asian Handicap") ) {
                continue;
              }
            }
            if( this.users.mix_basket_sp == 1 ) {
              if( (obj.odds_name == "1st Quarter Handicap" && value.odds_name == "1st Quarter Over/Under") || (obj.odds_name == "1st Quarter Over/Under" && value.odds_name == "1st Quarter Handicap") ) {
                continue;
              }
            }
          }

          this.betData = this.betData.filter((element) => element !== value);
          localStorage.setItem("betData", JSON.stringify(this.betData));
          EventBus.$emit('remove_bet', value.selected.id);
        }
      }

      if( !bExist )
      {
        this.betData.push(obj);
        localStorage.setItem("betData", JSON.stringify(this.betData));
        EventBus.$emit('add_bet', obj.selected.id);
      }
      
      this.calc();
    });

  },
  updated() {
    
  },
  methods: {
    async showRule(){
      await this.$api('http://server.honey-game.com/games/rule', 'post', {
        at: localStorage.getItem("accessToken"),
        rt: localStorage.getItem("refreshToken"),
      }).then(datas =>{
        if( datas.result == 0 ){
          this.$swal({
            icon: 'error',
            title: '오류가 발생하였습니다',
            text: datas.msg,
            customClass: {
              confirmButton: 'btn btn-error',
            },
          });
          return;
        }
        this.rullData = datas.rule;
        this.$refs['modal-rule'].show();
      })
    },
    getFormatNumber(num){
      if(num)
      {
        var regexp = /\B(?=(\d{3})+(?!\d))/g;
        return num.toString().replace(regexp, ',');
      }
    },
    updateNow() {
      var today = new Date();

      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var hours = ('0' + today.getHours()).slice(-2); 
      var minutes = ('0' + today.getMinutes()).slice(-2);
      var seconds = ('0' + today.getSeconds()).slice(-2); 

      this.now = year + '-' + month  + '-' + day + ' ' + hours + ':' + minutes  + ':' + seconds;
    },
    addMoney(money) {
      this.bet_money = parseInt(this.bet_money.replace(/,/g, "")) + parseInt(money);
      this.calc();
    },
    onResize() {
      //this.scroll_height = document.documentElement.clientHeight - 500;
    },
    removeBet(item){
      this.betData = this.betData.filter((element) => element !== item);
      localStorage.setItem("betData", JSON.stringify(this.betData));
      EventBus.$emit('remove_bet', item.selected.id);
      this.calc();
    },
    removeAll(){
      const temp = [];
      localStorage.setItem("betData", JSON.stringify(temp));
      for(var value of this.betData)
      {
        EventBus.$emit('remove_bet', value.selected.id);
      }
      this.betData = [];
      EventBus.$emit('remove_all');
      this.calc();
    },
    resetMoney(){
      this.bet_money = 0;
      this.calc();
    },
    calc(){
      this.total_odds = 1;
      this.folder_for_bonus = 0;

      for(var value of this.betData)
      {
        // 보너스 배당 체크
        if( this.users.use_bonus == 1)
        {
          if( parseFloat(value.selected.odds) >= this.users.min_rate_for_bonus )
          {
            this.folder_for_bonus++;
          }
        }

        this.total_odds *= value.selected.odds;
      }

      if( this.folder_for_bonus >= parseInt(this.users.min_folder_for_bonus3) && parseInt(this.users.min_folder_for_bonus3) != 0 )
      {
        this.total_odds *= parseFloat(this.users.bonus_rate3);
        this.bonus_rate = this.users.bonus_rate3;
        this.bonus = true;
      } else if( this.folder_for_bonus >= parseInt(this.users.min_folder_for_bonus2) && parseInt(this.users.min_folder_for_bonus2) != 0 ) {
        this.total_odds *= parseFloat(this.users.bonus_rate2);
        this.bonus_rate = this.users.bonus_rate2;
        this.bonus = true;
      } else if( this.folder_for_bonus >= parseInt(this.users.min_folder_for_bonus1) && parseInt(this.users.min_folder_for_bonus1) != 0 ) {
        this.total_odds *= parseFloat(this.users.bonus_rate1);
        this.bonus_rate = this.users.bonus_rate1;
        this.bonus = true;
      } else {
        this.bonus_rate = 0;
        this.bonus = false;
      }
    },
    confirmBetting(){
      this.$swal({
        title: '',
        text: "베팅하시겠습니까?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-h',
        },
        buttonsStyling: false,
      }).then (result => {
        if (result.value) {
          this.betting();
        };
      })
    },
    async betting()
    {
      const at = localStorage.getItem("accessToken");
      const rt = localStorage.getItem("refreshToken");
    
      await this.$api('http://server.honey-game.com/games/betting', 'post', {
        betData: this.betData,
        betMoney: this.bet_money,
        at: at,
        rt: rt
      }).then(datas =>{
        this.$swal({
          icon: (datas.result)?'success':'error',
          title: (datas.result)?'베팅성공':'베팅실패',
          html: datas.msg,
          customClass: {
            confirmButton: 'btn btn-' + (datas.result)?'success':'error',
          },
        })
        if(datas.result == 1){
          //this.users = datas.users;
          this.checkAuth();
          this.removeAll();
          this.bet_money = 0;
        }
      })
    },
    async showBetList(){
     this.$refs['modal-bet-list'].show();
    },

    checkAuth(){
      const at = localStorage.getItem("accessToken");
      const rt = localStorage.getItem("refreshToken");

      this.$api('http://server.honey-game.com/auth/account', 'post', {
        'at' : at,
        'rt' : rt
      }).then((res)=>{
        if(res.result == 1 ) {
          this.users = res.userData;
          useJwt.setToken(res.access_token)
          useJwt.setRefreshToken(res.refresh_token)
          localStorage.setItem('userData', JSON.stringify(this.users))
        } else {
          this.$router.replace('/pages/miscellaneous/not-authorized');
        }
      });
    },
    comma(obj) {
      return Number(obj).toLocaleString();
    },
    uncomma(str) {
      str = str.toString().replace(/,/gi, '');
      return (new Number(str));//문자열을 숫자로 반환
    },
    getDay,
    getTime,
    makeLeagueImg,
    makeTeamImg,
  },
  beforeDestroy(){
    EventBus.$off();
  },
  setup(){
    const perfectScrollbarSettings = {
      suppressScrollX : true,
      scrollingThreshold: 100000,
      wheelSpeed:1,
      swipeEasing:true,
    }
    const { appName, appLogoImage, megalineLogo, honeyLogo, demoLogo, madeLogo, eagleLogo, jumpLogo, bingoLogo, aromaLogo, assaLogo, bbrLogo } = $themeConfig.app
    return {
      perfectScrollbarSettings, appName, appLogoImage, megalineLogo, honeyLogo, demoLogo, madeLogo, eagleLogo, jumpLogo, bingoLogo, aromaLogo, assaLogo, bbrLogo
    }
  },
}
</script>

<style>
.game-scroll-area {height:465px;min-height:150px; padding:0px 3px;}
.game-cart-area .cart-title { display:flex;justify-content: center; width:100%; background-color:#2b3654;padding:10px; color:#ffe588; border-bottom:1px solid #ffe588;}
.game-cart-area .cart-content { width:100%;min-height:150px;color:white;height:465px;background-color:#181d23;border:1px solid #3d4d78;}
.game-cart-area .cart-content-empty { width:100%;min-height:150px;height:calc(50% - 50px);color:darkgray;text-align:center;padding-top:60px;background-color:#252e48;border:1px solid #3d4d78;}

.game-cart-area .bet-data {background-color:#252e48; border:1px solid #3d4d78;margin-top:4px !important;}
.game-cart-area .odds-name {float:left;color:#ffe588;}
.game-cart-area .odds-rate {float:right;}
.game-cart-area .alert-primary {background: #2b3654 !important;}

.feather-trash-2 {cursor:pointer;}
.input-group-text {color:white;}

#modal-bet-list___BV_modal_outer_ {z-index:9999 !important;}
#modal-bet-list .modal-lg {max-width:600px !important;}
.modal-header .modal-title {text-align:center;}

.btn_money {border:1px solid #7367f0; color:#a7abb1; min-width:50px; padding:7px 0px;text-align:center;border-radius:1px;display: inline-block; cursor:pointer;}

.gradient-border {animation: changeBackgroundColor 3s infinite;}

@keyframes changeBackgroundColor {
  0% {
    background-color: blueviolet;
  }
  50% {
    background-color: #FF4136;
  }
  100% {
    background-color: blueviolet;
  }
}
</style>
